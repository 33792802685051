import React, { Component } from 'react';

class Lyric extends Component {

    

    render() {
        return (
            <div>
                <h1>Lyric page</h1>
            </div>
        );
    }
}

export default Lyric;
