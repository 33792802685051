import React from 'react'
import Tracks from '../tracks/Tracks'
function Index() {
  return (
   <div>
        <Tracks />  
    </div>
  )
}

export default Index;